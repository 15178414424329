import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../../api/axios';
import useAuthContext from '../../../Auth/AuthContext';
import Swal from 'sweetalert2';

const UserOtp = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [err, setError] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const inputRefs = useRef([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { TokenSave, getUser } = useAuthContext();
  const userValue = location.state?.value;

  useEffect(() => {
    if (!userValue) {
      navigate('/');
    }
  }, [userValue, navigate]);

  useEffect(() => {
    if (otp.every((digit) => digit !== '')) {
      handleVerifyOtp();
    }
  }, [otp]);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [secondsLeft]);

  const handleVerifyOtp = async () => {
    if (otp.some((digit) => digit === '')) {
      setError(true);
      return;
    }
    setLoadingVerification(true);

    try {
      const apiURL = `${process.env.REACT_APP_AUTH_URL}/email-login`;

      const uuid = localStorage.getItem('uuid') || '667676';
      const response = await axiosInstance.post(apiURL, {
        email: userValue,
        code: otp.join(''),
        uuid: uuid,
      });

      setLoadingVerification(false);

      if (response.status === 200) {
        Swal.fire('Success', 'OTP verification successful!', 'success');
        TokenSave('access_token', response.data.token);
        getUser();
        navigate(-2);
      } else {
        Swal.fire('Error', `Failed to verify OTP. Status Code: ${response.status}`, 'error');
        setError(true);
      }
    } catch (error) {
      setLoadingVerification(false);

      let errorMessage = 'An error occurred. Please try again.';

      if (error.response) {
        errorMessage = `Error: ${error.response.data?.messages || 'Server error'}. Status Code: ${error.response.status}`;
      } else if (error.request) {
        errorMessage = 'No response from the server. Please check your connection.';
      } else {
        errorMessage = `Error in request setup: ${error.message}`;
      }

      Swal.fire('Error', errorMessage, 'error');
    }
  };

  const resendOTP = async () => {
    setLoadingResend(true);

    try {
      const apiURL = `${process.env.REACT_APP_AUTH_URL}/send-email-otp`;
      const response = await axiosInstance.post(apiURL, {
        email: userValue,
      });

      if (response.status === 200) {
        Swal.fire('Success', 'OTP resent successfully!', 'success');
        setSecondsLeft(60);
        setIsButtonDisabled(true);
      } else {
        Swal.fire('Error', 'Failed to resend OTP.', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while resending the OTP.', 'error');
    } finally {
      setLoadingResend(false);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value !== '' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text').split('').filter((char) => !isNaN(char)).slice(0, 6);
    const newOtp = [...otp];

    pastedData.forEach((char, index) => {
      newOtp[index] = char;
    });

    setOtp(newOtp);

    if (pastedData.length === 6) {
      inputRefs.current[5].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <Outlet />
      <div className="my-5">
        <div className="card shadow p-4 mx-auto" style={{ maxWidth: '500px' }}>
          <h3 className="text-center mb-3">Enter the OTP sent to your email address</h3>
          <p className="text-center text-muted mb-4">
            We've sent the OTP to <span className="text-primary">{userValue}</span>
          </p>

          <div className="d-flex justify-content-center mb-3">
            {otp.map((data, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                className="form-control text-center mx-1 otp-input"
                style={{ width: '50px', fontSize: '24px' }}
                maxLength={1}
                value={data}
                onChange={(e) => handleOtpChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                onFocus={(e) => e.target.select()}
              />
            ))}
          </div>

          {err && <div className="invalid-feedback text-center mb-3">Please enter a valid OTP.</div>}

          <div className="d-grid gap-2">
            <button
              className={`btn btn-primary ${loadingVerification ? 'disabled' : ''}`}
              onClick={handleVerifyOtp}
              disabled={otp.some((digit) => digit === '')}
            >
              {loadingVerification ? 'Verifying...' : 'Verify OTP'}
            </button>

            <button
              onClick={resendOTP}
              className="btn btn-outline-secondary"
              disabled={isButtonDisabled || loadingResend}
            >
              {loadingResend
                ? 'Resending...'
                : isButtonDisabled
                ? `Resend in ${secondsLeft}s`
                : 'Resend OTP'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOtp;
