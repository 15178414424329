import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG} from '../../../../_metronic/helpers'
import useAuthContext from '../../../Auth/AuthContext'
import UsersAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/UsersAutoComplete'

export const AddUsersCredit = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [fileName, setFileName] = useState('No selected file')
  const navigate = useNavigate()
  const {check_role} = useAuthContext()
  const [user_value, setUSER_Value] = useState(null)

  const [data, setData] = useState({
    credit_number: '',
    status: false,
  })

  // Handle form input changes
  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  // Centralized error handling for cleaner code
  const handleErrors = (errorResponse) => {
    if (errorResponse.status === 422) {
      setErrors(errorResponse.data.errors)
    }
    setLoading(false)
  }

  // Handle form submit for adding/editing user credit
  const handleEdit_Add_submit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => formData.append(key, value))
    formData.append('user_id', user_value.id)
    try {
      await axiosInstance.post(`/Admin-user-credit`, formData)
      Swal.fire({
        title: intl.formatMessage({id: 'Success'}),
        icon: 'success',
        confirmButtonText: 'OK',
      })
      navigate(`/Users-Credit`)
    } catch (error) {
      handleErrors(error.response)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!check_role('add-user-credits')) {
      navigate('/')
    }
  }, [check_role, navigate])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Credits'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Credits'})}
            </li>
          </ul>
        </div>
        <div>
          <Link to='/Users-Credit' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </Link>
        </div>
      </div>

      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* Form Container */}
        <form
          onSubmit={handleEdit_Add_submit}
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            {/* Status Toggle */}
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.ShowStatus'})}</h2>
                </div>
                <div className='card-toolbar'>
                  <div
                    className='rounded-circle w-15px h-15px bg-primary'
                    id='kt_ecommerce_add_product_status'
                  />
                </div>
              </div>
              <div className='card-body py-0'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-30px w-50px'
                    onChange={(e) =>
                      handleChange({target: {name: 'status', value: e.target.checked}})
                    }
                    type='checkbox'
                    checked={data.status || false}
                    id='flexSwitch30x50'
                    name='status'
                  />
                  <label className='form-check-label' htmlFor='flexSwitch30x50'>
                    {intl.formatMessage({id: 'Form.status'})}{' '}
                    {intl.formatMessage({
                      id: data.status ? 'Form.Enabled' : 'Form.Disabled',
                    })}
                  </label>
                </div>
              </div>
            </div>

            {/* Form Fields */}
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.General'})}</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='my-3 col-12 fv-row fv-plugins-icon-container'>
                  <label className='required form-label'>
                    {' '}
                    {intl.formatMessage({id: 'Form.Select_User'})}{' '}
                  </label>
                  <UsersAutoComplete value={user_value} setValue={setUSER_Value} />
                </div>

                {/* Credit Number Input */}
                <div className='my-3 fv-row'>
                  <label className='required form-label'>
                    {intl.formatMessage({id: 'Form.credit_number'})}
                  </label>
                  <input
                    type='number'
                    name='credit_number'
                    className='form-control mb-2'
                    value={data.credit_number}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({id: 'Form.credit_number'})}
                  />
                  {errors.credit_number && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='fv-help-block'>
                        {errors.credit_number}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                {!loading ? (
                  <span className='indicator-label'>{intl.formatMessage({id: 'Form.Create'})}</span>
                ) : (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'Form.Pleasewait'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
