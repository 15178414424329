import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

const BanHome = () => {
  // Initialize state for check
  const [check, setCheck] = useState({
    "browse": false,
    "add": false,
    "show": false,
    "edit": false,
    "delete": false,
  })

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const {check_role} = useAuthContext() // Use check_role function from context
  const navigate = useNavigate()

  // Set the values of check state
  useEffect(() => {
    setCheck({
      browse: check_role('browse-ban'),
      add: check_role('add-ban'),
      show: check_role('show-ban'),
      edit: check_role('edit-ban'),
      delete: check_role('delete-ban'),
    })

    if (!check_role('browse-ban')) {
      navigate('/')
    }
    getData()
  }, [])

  // Fetching Data for the Bans Table
  const getData = async () => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get('/Admin-bans') // Updated endpoint
      setItems(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  // Deleting a Ban
  const deleteBan = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/Admin-bans/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleted!',
          text: 'The ban has been deleted.',
          icon: 'success',
        })
      }
    })
  }

  // CustomDiv Component to handle Add Button
  const CustomDiv = () => (
    <div className="row flex-end w-100">
      {check.add && (
        <div className="col-auto">
          <Link to='/Bans/Add-Bans' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Bans.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  // Defining Table Columns
  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Value'}),
      accessor: 'value',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.value}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Type'}),
      accessor: 'type',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.type}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link to={`/Bans/Edit-Bans/${row.original.id}`} className='btn me-2 btn-light-primary'>
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}

          {check.delete && (
            <button onClick={() => deleteBan(row.original.id)} className='btn me-2 btn-light-danger'>
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  // Main Render
  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Bans'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Bans'})}</li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <ReactTable columns={columns} data={items} customComponent={<CustomDiv />} />
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default BanHome
